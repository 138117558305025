select option.disabled {
    background-color:Chartreuse;
}

select option.disabled:disabled {
    color:yellow;
    background-color:blue;
    font-weight:bold;
}

select option:hover {
    color:pink;
    background-color:OrangeRed;
}

select option.disabled:hover {
    color:grey;
}
option.pruebita{
    color: #0C9A9A !important;
    background-color: #5a6e8a;
}